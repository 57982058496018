<template>
    <v-container fluid>
        <v-row row>
            <v-col xs="12" lg12 md="12">
                <v-subheader>
                    <h2>Templates</h2>
                </v-subheader>
            </v-col>
        </v-row>
        <v-card class="ma-2 pa-2" :loading="store.state.loading">
            <v-form ref="form">
                <v-container fluid>
                    <v-row row wrap class="pa-2">
                        <v-col>
                            <v-text-field required class="required" label="Nome do template" v-model="store.state.title"
                                :rules="[$functions.validationRequiredField]" />
                        </v-col>
                        <v-col xs="4" md="4" lg="4">
                            <v-select label="Layout" single-line v-model="store.state.layout"
                                :items="store.layoutList"></v-select>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <v-card-actions class="pa-3">
                        <v-btn @click="addItemOnStart" color="lightgrey" text outlined>
                            + Adicionar item no início
                        </v-btn>

                        <v-spacer />

                        <v-btn color="green" style="color: white" @click="saveForm"> Salvar </v-btn>
                        <v-btn color="error" text @click="back"> Cancelar </v-btn>
                    </v-card-actions>

                    <item-register v-for="(item, index) of lazyLoadedItems" :index="index" :key="item" :store="store"
                        @saveForm="saveForm" v-model="store.state.items[index]" />

                    <v-divider></v-divider>

                    <v-row v-show="store.state.loading == true">
                        <v-col class="d-flex flex-column mx-auto my-4">
                            <span class="flex-one mx-auto my-3">
                                Carregando Novas Questões
                            </span>
                            <v-progress-circular class="mx-auto flex-one form-register-loader" indeterminate />
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
import TemplateStore from "./store/TemplateStore.js";
import ItemRegister from "@/modules/forms/presenter/components/item_management/ItemRegister.vue";

export default {
    name: "DocumentTemplateRegister",
    components: { ItemRegister },
    data() {
        return {
            id: null,
            store: new TemplateStore(),
        };
    },
    async mounted() {
        this.id = this.$route.params.id;

        if (this.id) await this.getData();
        else this.store.clearData();

        this.implementInfinitScrolling();
    },
    computed: {
        lazyLoadedItems() {
            const serializedPage = this.store.state.page || 1;
            const result = this.store.state.items?.slice(0, serializedPage * this.store.state.perPage);
            return result;
        },
    },
    methods: {
        implementInfinitScrolling() {
            window.onscroll = () => {
                let bottomOfWindow =
                    document.documentElement.scrollTop + window.innerHeight ===
                    document.documentElement.offsetHeight;

                if (bottomOfWindow) this.loadMoreData();
            };
        },

        loadMoreData() {
            if (this.store.state.items.length < this.store.state.perPage) return;

            this.store.state.loading = true;

            setTimeout(() => {
                this.store.state.page += 1;
                this.store.state.loading = false;
            }, 1800);
        },
        addItemOnStart() {
            const item = this.store.createItem();
            this.store.addItem(item);
        },
        addNewItemAboveThis(index) {
            const item = this.store.createItem();
            this.store.addItem(item, index + 1);
        },
        async getData() {
            try {
                this.store.setLoading(true);

                const response = await this.minamelServices.DocumentTemplateService().show(this.id);

                this.store.state.title = response.title;
                this.store.state.layout = response.layout;
                this.store.state.items = response.fields;
                this.store.state.template_id = response.id;

                this.store.state.items.forEach((item) => item.config = JSON.parse(item.config));
            } catch (error) {
                console.error(error);
            } finally {
                this.store.setLoading(false);
            }
        },
        async saveForm() {
            try {
                this.store.setLoading(true);

                if (!this.$refs || !this.$refs.form) return;

                let formValidate = this.$refs.form.validate();

                if (!formValidate) {
                    this.showSnackbar("Verifique os campos em destaque.");
                    return;
                }

                if (this.store.state.items.length) {
                    this.store.sortAllItems();
                }

                if (this.id) await this.update();
                else await this.create();

                this.showSnackbar("Formulário salvo com sucesso.", "success");
                this.back();
            } catch (e) {
                this.showSnackbar(e);
                console.error("DocumentTemplateRegister::saveForm -", e);
            } finally {
                this.store.setLoading(false);
            }
        },
        async create() {
            try {
                this.store.state.type = "RECORD";

                await this.minamelServices.DocumentTemplateService().create(this.store.state);
                this.showSnackbar("Template incluído com sucesso!", "success");
            } catch (err) {
                console.error("DocumentTemplateRegister::create - ", err);
                throw err;
            }
        },
        async update() {
            try {
                await this.minamelServices.DocumentTemplateService().update(this.store.state.template_id, this.store.state);
                this.showSnackbar("Template atualizado com sucesso!", "success");
            } catch (err) {
                console.error("DocumentTemplateRegister::update - ", err);
                throw err;
            }
        },
        showSnackbar(message, color = "warning") {
            this.$root.$SnackBar.show({
                message: message,
                visible: false,
                color: color,
                timeout: 6000,
                x: null,
                y: "bottom",
                multiLine: false,
            });
        },
        back() {
            this.$router.back();
        },
    }
}

</script>